import React from "react";
import { SurveyContext } from "../../providers";

export function PercentageBar() {
  const { percentComplete } = React.useContext(SurveyContext);

  return percentComplete>0 && (
    <div className="w-full bg-secondary/30 rounded-full">
      <div
        className={`
				font-medium text-center text-light p-1 leading-none rounded-lg duration-500 ease-in-out
				${percentComplete === 0 ? "bg-none" : percentComplete === 100 ? "bg-success" : "bg-primary"}
				`}
        style={{ width: `${percentComplete}%` }}
      >
        {percentComplete>0 && <span>{percentComplete}%</span>}
      </div>
    </div>
  );
}
