import { Answers as IAnswers } from "@careerinsight/applib-common/entity";
import { getErrorMessage } from "../util.js";
import { appSettings } from "../index";

export async function saveAnswers(surveyInstanceSecretKey: string, answers: IAnswers) {
  const response = await fetch(appSettings.surveyApi + "/surveys/" + surveyInstanceSecretKey + "/answers", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(answers),
  });

  if (response.status !== 200) {
    const content = await response.json();
    console.error(content);
    throw new Error(getErrorMessage(content));
  }
}
