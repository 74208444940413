import React, { useEffect } from "react";
import { SelectQuestion } from "@careerinsight/applib-common/entity";
import { SurveyContext, ValidationContext } from "../../providers";
import { QuestionLabel } from "../../components";

export const SelectWidget: React.FC<{ question: SelectQuestion }> = ({ question }) => {
  const { answers, setAnswer } = React.useContext(SurveyContext);
  const { setValid, removeValid, showValidation } = React.useContext(ValidationContext);
  const answer: string = answers[question.questionKey] ? (answers[question.questionKey] as string) : "";

  function isValid() {
    if (!question.required) return true;
    return !!answers[question.questionKey];
  }

  useEffect(() => {
    // When this widget mounts, or whenever 'answers' changes (i.e. because the user answers a question) then
    // recompute the validation status of this answer and write it to the global validation status held in
    // ValidationProvider
    setValid(question.uniqueId, isValid());

    // When this widget unmounts (i.e. is no longer shown) then remove the question key from the global validation
    // status.  This is necessary because we may have an invalid answer for a conditional question which disappears
    // because the user changed the response to a different question.
    return () => removeValid(question.uniqueId);
  }, [answers]);

  const invalid = showValidation && !isValid();

  return (
    <li className="block">
      <QuestionLabel question={question} />

      <select
        className={
          "border rounded-md block w-full p-2 " +
          (invalid ? "border-danger text-danger" : "bg-secondary-light/20 border-secondary-border")
        }
        id={question.uniqueId}
        value={answer}
        onChange={(event) => {
          setAnswer(question, event.target.value);
        }}
      >
        <option value="" disabled>
          Please select
        </option>

        {question.titleMap.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>

      {invalid && <small className="text-danger font-semibold">Please select a value</small>}
    </li>
  );
};
