import React from "react";
import { SurveyQuestion } from "@careerinsight/applib-common/entity";

export const QuestionLabel: React.FC<{ question: SurveyQuestion }> = ({ question }) => {
  if (!question.label) return null;

  return (
    <div className="mb-1">
      <label className="font-bold me-2" htmlFor={question.uniqueId}>
        {question.label}
      </label>
      {question.required && (
        <span
          className="rounded-md text-light px-1.5 pb-0.5 text-xs bg-danger"
          style={{ position: "relative", top: "-1px" }}
        >
          Required
        </span>
      )}
    </div>
  );
};
