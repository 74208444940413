import React from "react";

export const SurveyFooter = ({
  pageNumber,
  previousPage,
  nextPage,
  pageCount,
  finishSurvey,
}: {
  pageNumber: number;
  previousPage: () => void;
  nextPage: () => void;
  pageCount: number;
  finishSurvey: () => void;
}) => {
  const firstPage = pageNumber === 0;
  const lastPage = pageNumber === pageCount - 1;

  return (
    <div className="flex gap-4 justify-end">
      <button
        className={`
					border border-secondary-border p-2 rounded w-32 me-3
					${!firstPage ? "bg-light hover:bg-secondary-light" : ""}
					${firstPage ? "text-secondary" : ""}
				`}
        type="button"
        onClick={previousPage}
        disabled={firstPage}
      >
        &lt; Previous
      </button>

      {!lastPage && (
        <button
          className="border border-secondary-border p-2 bg-light hover:bg-secondary-light rounded w-32"
          type="button"
          onClick={nextPage}
        >
          Next &gt;
        </button>
      )}
      {lastPage && (
        <button
          className="text-light p-2 bg-success border border-success-border hover:brightness-[80%] rounded w-32"
          type="button"
          onClick={finishSurvey}
        >
          Finish
        </button>
      )}
    </div>
  );
};
