/**
 * Produces email-client-friendly HTML
 */
export interface EmailRenderer {

	/**
	 * @param url the base URL of the Career Insight instance, without trailing slash. Used to retrieve
	 * ${url}/assets/email-header.png
	 */
	header(url: string): string;


	/**
	 * @param text if undefined, renders a paragraph break
	 */
	paragraph(text?: string): string;


	button(label: string, url: string): string;


	footer(): string;
}
