import React, { useEffect } from "react";
import { StringQuestion } from "@careerinsight/applib-common/entity";
import { SurveyContext, ValidationContext } from "../../providers";
import { QuestionLabel } from "../../components";

export const StringWidget: React.FC<{ question: StringQuestion }> = ({ question }) => {
  const { answers, setAnswer } = React.useContext(SurveyContext);
  const { setValid, removeValid, showValidation } = React.useContext(ValidationContext);
  const a: string = answers[question.questionKey] ? (answers[question.questionKey] as string) : "";
  const [state, setState] = React.useState<string>(a);
  const maxLength = question.maxLength ? question.maxLength : 1024;

  // See SelectWidget for comments on how validation works
  function isValid() {
    if (!question.required) return true;
    return !!answers[question.questionKey];
  }

  useEffect(() => {
    setValid(question.uniqueId, isValid());
    return () => removeValid(question.uniqueId);
  }, [answers]);

  const invalid = showValidation && !isValid();

  return (
    <li className="block">
      <QuestionLabel question={question} />

      <input
        maxLength={maxLength}
        className={`
					border rounded-md block w-full p-2
					${invalid ? "border-danger text-danger" : "bg-secondary-light/20 border-secondary-border"}
				`}
        id={question.uniqueId}
        value={state}
        onChange={(event) => {
          setState(event.target.value);
          setAnswer(question, event.target.value);
        }}
      />

      {invalid && <small className="text-danger font-semibold">Please answer this question</small>}
    </li>
  );
};
