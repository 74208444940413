import React from "react";
import { HtmlItem } from "@careerinsight/applib-common/entity";

export const HtmlWidget: React.FC<{ item: HtmlItem }> = ({ item }) => {
  return (
    <li className="block">
      <div dangerouslySetInnerHTML={{ __html: item.html }}></div>
    </li>
  );
};
