import React from "react";

export const ValidationContext = React.createContext<{
  /**
   * @param key a question key
   * @param isValid true if the answer is valid, false if the answer is invalid
   */
  setValid: (key: string, isValid: boolean) => void;

  /**
   * Removes the validation result from the global validation object. This must be done when the question widget
   * unmounts.
   * @param key a question key
   */
  removeValid: (key: string) => void;

  /**
   * This holds the current state of the validation. Individual question widgets must add their question key and
   * corresponding validation state to this object. When the widget unmounts, it must remove its question key from
   * this object.
   */
  validationState: Record<string, boolean>;

  /**
   * If showValidation is true, then widgets must show an error message in the case that the answer is invalid
   */
  showValidation: boolean;
  setShowValidation: (show: boolean) => void;
}>(null as any);

export const ValidationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [validationState, setValidationState] = React.useState<Record<string, boolean>>({});
  const [showValidation, setShowValidation] = React.useState(false);

  return (
    <ValidationContext.Provider
      value={{
        setValid: (key: string, isValid: boolean) => setValidationState((current) => ({ ...current, [key]: isValid })),

        removeValid: (key: string) =>
          setValidationState((current) => {
            delete current[key];
            return current;
          }),

        validationState,
        showValidation,
        setShowValidation,
      }}
    >
      {children}
    </ValidationContext.Provider>
  );
};
