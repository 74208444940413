import React from "react";

export const SurveyError: React.FC<{ errorMessage: string }> = ({ errorMessage }) => {
  return (
    <div>
      <h1 className="text-danger text-3xl font-bold">There might be a problem...</h1>
      <pre className="text-danger">{errorMessage}</pre>
    </div>
  );
};
